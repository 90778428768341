module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mindful-escapes","short_name":"mindful-escapes","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFE62E","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1c3ea312b0219d31e0c892cb86a79772"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://providers.mindfulescapes.com/graphql","verbose":true,"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":false,"hardCacheData":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":true,"copyQueryOnError":false,"panicOnError":true,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":null},"CoreParagraphBlockAttributesV2":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-PTEZEXGCQ0"],"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"EXGCnrHKJwT4lUCBadoizheKumGDnMW1","devKey":"dDO83J8svyKfulqgqGaY4KW68kVYb9Al"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
